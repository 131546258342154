import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda'
import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import Icon from '_components/Icon';
import { withCoveoNoIndexTags } from '_containers/BaseComponent';
import { getPortal, Portals } from '_utils/helpers';

import { SiteWideAnnouncementProps } from '../definitions';

import {
  ButtonWrapper,
  AnnouncementCTA,
  SecondaryButton,
  AnnouncementWrapper,
  TitleWrapper,
  Content,
  TextWrapper,
  IconWrapper,
  Trigger,
} from './StyledSiteWideAnnouncement';

const Icons = {
  Blue: <Icon name="check_circle" />,
  Yellow: <Icon name="warning_amber"/>,
  Red: <Icon name="error_outline" />
};

const SiteWideAnnouncement: React.FC<SiteWideAnnouncementProps> = ({
  description,
  dismissable,
  id,
  link,
  secondaryLink,
  title,
  type,
  setAnnouncementDismissed,
}) => {
  const [t] = useTranslation();
  const [hidden, setHidden] = useState(false);

  const handleClickDismiss = () => {
    setHidden(true);

    if (setAnnouncementDismissed) {
      setAnnouncementDismissed();
    }
  };

  const portal = getPortal(Portals.before);

  // Stops spacing going out of whack
  if (hidden || portal === null) {
    return null;
  }

  const hasDescription = description?.value !== '';

  return createPortal(
    <AnnouncementWrapper
      role="alert"
      alertStyle={type?.value || 'Yellow'}
      hasDescription={hasDescription}
      aria-hidden={hidden}
      id={id}
      tabIndex={hidden ? -1 : null}
      hidden={hidden}
      className={`${hidden ? 'announcement-hidden' : ''} site-wide-announcement`}
    >
      <IconWrapper>{Icons[type?.value || 'Yellow']}</IconWrapper>

      <TextWrapper>
        <TitleWrapper>
          <Text field={title} editable={false} />
        </TitleWrapper>

        {hasDescription && (
          <Content field={description} editable={false} tabIndex={hidden ? -1 : null} />
        )}

        <ButtonWrapper>

        <AnnouncementCTA
          field={link}
          fallbackTitle={link?.fallbackTitle}
          linkSize="s"
          linkTheme="none"
        />

        <SecondaryButton
          field={secondaryLink}
          fallbackTitle={secondaryLink?.fallbackTitle}
          linkSize="s"
          linkTheme="none"
        />
        </ButtonWrapper>
      </TextWrapper>

      {
        (dismissable?.value ?? false) && (
          <Trigger
            type="button"
            aria-label={`${t('accessibility-close')} ${title?.value}`}
            aria-controls={id}
            aria-expanded={!hidden}
            onClick={handleClickDismiss}
            tabIndex={hidden ? -1 : null}
          >
            <Icon name="close" ariaHidden />
          </Trigger>
        )
      }
    </AnnouncementWrapper>,
    portal
  );
};

export default compose(withCoveoNoIndexTags)(SiteWideAnnouncement);
