import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useState } from 'react';
import Icon, { MaterialIcons } from '_components/Icon';
import { InPageAnnouncementProps } from '../definitions';
import {
  AnnouncementCTA,
  AnnouncementWrapper,
  TitleWrapper,
  Content,
  TextWrapper,
  IconWrapper,
  CloseIconWrapper,
  ButtonWrapper
} from './StyledInPageAnnouncement';

const Icons = {
  Blue: 'error_outline' as MaterialIcons,
  Yellow: 'warning_amber' as MaterialIcons,
  Red: 'error_outline' as MaterialIcons,
  Green: 'check_circle' as MaterialIcons,
};

const InPageAnnouncement: React.FC<InPageAnnouncementProps> = ({
  customIcon,
  id,
  description,
  link,
  secondaryLink,
  title,
  type = { value: 'Blue' },
  isTitleBold,
  dismissable = { value: false }
}) => {

  const [hide, setHide] = useState(false);

  return hide ? null : <AnnouncementWrapper role="alert" alertStyle={type?.value} id={id} className="in-page-announcement">
  <IconWrapper>
    <Icon name={customIcon?.targetItem?.name ?? Icons[type?.value]} />
  </IconWrapper>
  {dismissable?.value && <CloseIconWrapper onClick={() => setHide(!hide)}>
    <Icon name={'close'} />
  </CloseIconWrapper>}
  <TextWrapper>
    <TitleWrapper isBold={isTitleBold}>
      <Text field={title} editable={false}/>
    </TitleWrapper>

    {description?.value && <Content field={description} editable={false} />}

    <ButtonWrapper>
      <AnnouncementCTA
        field={link}
        fallbackTitle={link?.fallbackTitle}
        linkSize="s"
        linkTheme="none"
      />

      <AnnouncementCTA
        field={secondaryLink}
        fallbackTitle={secondaryLink?.fallbackTitle}
        linkSize="s"
        linkTheme="none"
      />
    </ButtonWrapper>
    
  </TextWrapper>
</AnnouncementWrapper>
    
};

export default InPageAnnouncement;
