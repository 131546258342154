import styled, { css } from 'styled-components';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { Link } from '_utils/components';
import { spacing } from '_utils/props';
import {
  button,
  depth,
  focusOutline,
  hideOnPrint,
  mq,
  safeFocusRemoval,
  svg,
} from '_utils/styles';

const announcementThemes = {
  Blue: css`
    background-color: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.blue.background};
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.blue.borderColor};

    path {
      fill: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.pathFillColor};
    }
  `,
  Yellow: css`
    background-color: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.yellow.background};
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.yellow.borderColor};

    path {
      fill: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.pathFillColor};
    }
  `,
  Red: css`
    background-color: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.red.background};
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.red.borderColor};

    path {
      fill: ${({ theme }) => theme.siteWideAnnouncement.announcementThemes.pathFillColor};
    }
  `
};

const handleAnnouncementTheme = ({ alertStyle }) => announcementThemes[alertStyle];

export const AnnouncementWrapper = styled.div`
  ${handleAnnouncementTheme}

  align-items: flex-start;
  color: ${({ theme }) => theme.siteWideAnnouncement.announcementWrapper.color};
  display: flex;
  position: relative;
  z-index: ${depth.HEADER - 1};

  ${({ theme }) => spacing({ theme, p: { xs: 3, md: 2 } })};

  @media print {
    path {
      fill: ${({ theme }) => theme.siteWideAnnouncement.announcementWrapper.pathFillColor};
    }
  }
`;

export const IconWrapper = styled.div`
  ${svg(20, 20)}
  width: 20px;
  flex-shrink: 0;
  position: relative;
  top: 0px;

  ${({ theme }) => spacing({ theme, mb: { xs: '18px', md: 0 } })};

  ${mq('md')} {
    top: 2px;
  }
`;

export const TextWrapper = styled.div`
  ${({ theme }) => spacing({ theme, ml: 2 })};
  width: calc(100% - 60px);
`;

export const TitleWrapper = styled.h2`
  ${({ theme }) => theme.siteWideAnnouncement.titleWrapper.heading2};
  color: inherit;
  margin: 0;
  font-weight: bold !important;
  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }

  &:hover {
    text-decoration: none;
  }
`;

export const Content = styled(RichText)`
  ${({ theme }) => theme.siteWideAnnouncement.content.richText};
  margin: 0;
  ${({ theme }) => spacing({ theme, mt: '4px' })};

  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Trigger = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  ${({ theme }) => spacing({ theme, ml: 1 })};
  ${hideOnPrint}

  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const AnnouncementCTA = styled(Link)`
  ${({ theme }) => spacing({ theme, mt: '12px', mr: '16px' })};

  a {
    ${button('slim', 'announcementTertiary')};
    ${({ theme }) => theme.siteWideAnnouncement.announcementCta.heading2};

    ${mq.lessThan('md')} {
      width: 100%;
    }

    path {
      fill: currentColor;
    }
  }
`;

export const SecondaryButton = styled(Link)`
  ${({ theme }) => spacing({ theme, mt: '12px' })};
  
  a {
    ${({ theme }) => theme.siteWideAnnouncement.announcementCta.heading2};
    ${mq.lessThan('md')} {
      width: 100%;
    }
    color: inherit;
    path {
      fill: currentColor;
    }
  }
`;
