import styled, { css } from 'styled-components';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { Link } from '_utils/components';
import { spacing } from '_utils/props';
import {
  button,
  mq,
  svg,
  safeFocusRemoval,
  focusOutline,
} from '_utils/styles';

const announcementThemes = {
  Blue: css`
    background-color: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.blue.background};
    border: 1px solid;
    border-color: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.blue.borderColor};

    path {
      fill: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.pathFillColor};
    }
  `,
  Yellow: css`
    background-color: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.yellow.background};
    border: 1px solid;
    border-color: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.yellow.borderColor};

    path {
      fill: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.pathFillColor};
    }
  `,
  Red: css`
    background-color: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.red.background};
    border: 1px solid;
    border-color: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.red.borderColor};

    path {
      fill: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.pathFillColor};
    }
  `,
  Green: css`
    background-color:  ${({ theme }) => theme.inPageAnnouncement.announcementThemes.green.background};
    border: 1px solid;
    border-color:  ${({ theme }) => theme.inPageAnnouncement.announcementThemes.green.borderColor};

    path {
      fill: ${({ theme }) => theme.inPageAnnouncement.announcementThemes.pathFillColor};
    }
  `,
};

const handleAnnouncementTheme = ({ alertStyle }) => announcementThemes[alertStyle];

export const AnnouncementWrapper = styled.div`
  align-items: flex-start;
  border-radius: 4px;
  color: ${({ theme }) => theme.inPageAnnouncement.announcementWrapper.color};
  display: flex;
  flex-direction: column;
  position: relative;

  ${handleAnnouncementTheme}
  ${({ theme }) => spacing({ theme, mt: 3, mb: 3, p: { xs: 3, md: 2 } })};

  ${mq('md')} {
    flex-direction: row;
  }

  // Any subsequent stacking Announcements
  & + & {
    ${({ theme }) => spacing({ theme, mt: 0 })};
  }

  @media print {
    ${({ theme }) => spacing({ theme, p: 0 })};
  }
`;

export const IconWrapper = styled.div`
  ${svg(20, 20)}
  flex-shrink: 0;
  position: relative;
  top: 0px;
  width: 20px;

  ${({ theme }) => spacing({ theme, mb: {xs: '18px', md: 0 }})};

  ${mq('md')} {
    top: 2px;
  }
`;

export const CloseIconWrapper = styled.div`
  ${svg(20, 20)}
  position: absolute;
  top: 16px;
  right:16px;
  width: 20px;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  ${({ theme }) => spacing({ theme, ml: {xs: 0, md: 2 }})};
  width: 100%;
`;

export const TitleWrapper = styled.h2`
  ${({ theme }) => theme.inPageAnnouncement.titleWrapper.heading2}
  color: inherit;
  margin: 0;

  &:focus {
    ${safeFocusRemoval}
    ${focusOutline}
  }

  ${({ isBold }) => {
    if(isBold) {
      return css`
        font-weight: bold !important;
      `
    }
  }}

  &:hover {
    text-decoration: none;
  }
`;

export const Content = styled(RichText)`
  ${({ theme }) => theme.inPageAnnouncement.content.richText};
  margin: 0;
  ${({ theme }) => spacing({ theme, mt: '4px' })};

  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

// CTA
export const AnnouncementCTA = styled(Link)`
${({ theme }) => spacing({ theme, mt: '12px', mr: '8px' })};
a {
  ${button('slim', 'announcementTertiary')};
  ${({ theme }) => theme.inPageAnnouncement.announcementCta.text};

  ${mq.lessThan('md')} {
    width: 100%;
  }

  path {
    fill: currentColor;
  }
}
`;