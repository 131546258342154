export const renderField = (editMode, field, markup) => {
  if (editMode === false && !field?.value) {
    return null;
  }
  return markup;
};

/**
 * CPA AU or In The Black differentiator known keys, derived from __JSS_STATE__ rendered in the DOM.
 * Corporate: https://www.cpaaustralia.com.au/
 * InTheBlack: https://intheblack.cpaaustralia.com.au
 *
 * Currently the sitecoreContext has not been contracted to a 'type' or 'interface'.
 * Once we do that we can remove the use of 'any' from the input parameter on both functions.
 */
export const SITECORE_CONTEXT_CORPORATE: string = "Corporate".toLocaleLowerCase();
export const SITECORE_CONTEXT_ITB: string = "InTheBlack".toLocaleLowerCase();

export const isCorporateSite = (sitecoreContext: any): boolean => {
  const name: string | undefined = sitecoreContext?.site?.name;
  return name !== undefined && (name as string).toLocaleLowerCase() === SITECORE_CONTEXT_CORPORATE;
};

export const isInTheBlackSite = (sitecoreContext: any): boolean => {
  const name: string | undefined = sitecoreContext?.site?.name;
  return name !== undefined && (name as string).toLocaleLowerCase() === SITECORE_CONTEXT_ITB;
};

export const STABLE_CLASS = 'stable';
export const MUTATING_CLASS = 'mutating';
export const TRANSITIONING_CLASS = 'isTransitioning';
export const INVISIBLE_CLASS = 'invisible';

export const handleBodyChanges = (site) => {

  // courtesy classes just to aid debugging, no actual visual changes
  document.body.classList.add(MUTATING_CLASS);
  document.body.classList.remove(STABLE_CLASS);

  let counter = 1;
  const interval = 20; // milliseconds
  const threshold = 20; // muliple of interval so = 400ms
  const normalizedSite = typeof site === 'string' ? site.toLowerCase() : '';
  const timeoutPeriod = 0;

  const timer = setInterval(() => {
    if (counter === threshold) {
      clearInterval(timer);
      // dispatch event for Adobe Target to listen to
      if (window?._satellite?.track) {
        window._satellite.track('pageContent'); // don't use `emitTrackEvent` as it has 50ms delay
      }
      window.pageIsStable = true;
      // courtesy classes just to aid debugging, no actual visual changes
      document.body.classList.remove(MUTATING_CLASS);
      document.body.classList.add(STABLE_CLASS);

      // fallback to show app if Adobe Launch fails to
      setTimeout(() => {
        document.body.classList.remove(TRANSITIONING_CLASS);
      }, timeoutPeriod);

    } else {
      counter++;
    }
  }, interval);
}
